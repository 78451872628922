<template>
    <v-row>
        <v-col cols="12" md="4">
            <v-card class="mx-auto">
                <v-card-title>Informações</v-card-title>
                <v-divider />
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-text>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-identifier</v-icon>
                        <span class="font-weight-bold">ID:</span>
                        {{dados.idgrupo}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-account-outline</v-icon>
                        <span class="font-weight-bold">Grupo:</span>
                        {{dados.grupo}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-account</v-icon>
                        <span class="font-weight-bold">Total de usuários:</span>
                        {{dados.qtdusuarios}}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="8">
            <v-row>
                <v-col>
                    <UsuarioGrupoListaPermissao :idgrupo="idgrupo" :alterarPermissao="true" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <Table
                        :pgLimitGet="5"
                        backendDirectory="usuario/listar"
                        :busca="{
                            idgrupo: parseInt(idgrupo),
                            busca: usuarios.busca || null
                        }"
                        :filter="true"
                        :headers="{
                            erpidusuario: {nome: 'ID CISS', tipo: 'texto'},
                            usuario: {nome: 'Usuário', tipo: 'texto'},
                            nome: {nome: 'Nome', tipo: 'texto'},
                            status: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo'},
                        }"
                    >
                        <v-col>Usuários</v-col>
                        <v-col>
                            <v-text-field
                                :disabled="carregando"
                                v-model="usuarios.busca"
                                label="Buscar"
                                outlined
                                dense
                            />
                        </v-col>
                    </Table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Table from "../Widgets/Table";
import UsuarioGrupoListaPermissao from "../Usuario/UsuarioGrupoListaPermissao";
import { mapState } from "vuex";
import axios from "axios";

export default {
    components: { Table, UsuarioGrupoListaPermissao },
    name: "UsuarioGrupoGet",
    props: ["idgrupo"],
    data: () => ({
        carregando: false,
        dados: {},
        usuarios: {},
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit"]),
    },
    methods: {
        get() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}usuario/grupo/get`, {
                    idgrupo: parseInt(this.idgrupo),
                })
                .then((res) => {
                    this.dados = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
    },
    created() {
        this.get();
    },
};
</script>

<style scoped>
</style>